import { inject, Injectable } from '@angular/core';
import { GeneralParameterInterface, generalParameterResponseInterface } from '../interfaces/general-parameter';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RoleResponseInterface } from '../interfaces/roles.interface';
import { Observable, of } from 'rxjs';

@Injectable({
      providedIn: 'root'
})
export class ParametersService {

      private http = inject(HttpClient);
      private apiUrl = environment.apiUrl;

      // Ciudades y departamentos

      getCitiesByDepartments(departmentId: number) {
            return this.http.get(this.apiUrl + '/ciudades/departamento/' + departmentId);
      }

      getCities() {
            return this.http.get<generalParameterResponseInterface>(this.apiUrl + '/ciudades');
      }

      getDepartments() {
            return this.http.get(this.apiUrl + '/departamentos');
      }


      // Bancos y creditos

      getBanks() {
            return this.http.get(this.apiUrl + '/bancos');
      }


      // Roles

      getAllRoles() {
            return this.http.get<RoleResponseInterface>(this.apiUrl + '/roles/all');
      }

      	/**
	 * Obtiene una lista de tipos de negocios desde el API.
	 *
	 * @returns {Observable<any>} Observable con la lista de tipos de negocios
	 */
	getBusinessTypes() {
		return this.http.get(`${this.apiUrl}/clientes-tipos-negocios`);
	}
}
